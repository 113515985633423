import { createContext, useMemo, useState } from 'react';

interface SessionModalContextType {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue: SessionModalContextType = {
  showModal: true,
  setShowModal: () => {},
};

export const SessionModalContext = createContext(defaultValue);

interface SessionModalContextProviderProps {
  children: React.ReactNode;
}

export const SessionModalContextProvider = (props: SessionModalContextProviderProps) => {
  const { children } = props;
  const [showModalState, setShowModalState] = useState<boolean>(defaultValue.showModal);

  const contextValue = useMemo(
    () => ({
      showModal: showModalState,
      setShowModal: setShowModalState,
    }),
    [showModalState]
  );

  return (
    <SessionModalContext.Provider value={contextValue}>{children}</SessionModalContext.Provider>
  );
};
