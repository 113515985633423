import Logo from 'src/Presentation/components/logo';
import { Box } from '@mui/material';
import AutocompleteSectorsAndIndustries from './AutocompleteSectorsAndIndustries';
import SearchTerms from './SearchTerms';
import AccountPopover from '../AccountPopover';

export default function HeaderMainPageMobile() {
  return (
    <Box sx={{ mb: -2 }}>
      <Box
        gap={2}
        display="grid"
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)' }}
        sx={{ ml: 2, mt: 2, mb: 4 }}
      >
        <Logo />

        <AccountPopover />
      </Box>
      <Box
        gap={2}
        display="grid"
        gridTemplateColumns={{ md: 'repeat(1, 1fr)' }}
        sx={{ ml: 2, mt: 2, mr: 2 }}
      >
        <AutocompleteSectorsAndIndustries />

        <SearchTerms />
      </Box>
    </Box>
  );
}
