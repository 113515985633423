import { Snackbar, Alert } from '@mui/material';
import { useContext } from 'react';
import AlertNotificationContext from 'src/Presentation/context/AlertNotificationContext';

interface AlertNotificationProps {
  top?: number;
}

export default function AlertNotification({ top = 10 }: AlertNotificationProps) {
  const { alertNotification, setAlertNotification } = useContext(AlertNotificationContext);

  const closeAlert = () => {
    setAlertNotification((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  return (
    <Snackbar
      open={alertNotification.showAlert}
      autoHideDuration={6000}
      onClose={closeAlert}
      sx={{ mt: top }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={closeAlert}
        severity={alertNotification.severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {alertNotification.message}
      </Alert>
    </Snackbar>
  );
}
