import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import FirstEntryPageLayout from '../layouts/firstEntryPage/FirstEntryPageLayout';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../../config-global';
//
import {
  Page404,
  MainPage,
  FirstEntryPage,
  Favorite,
  Template,
  LoginPage,
  RegisterPage,
  AccountActivation,
  ResetPasswordPage,
  NewPasswordPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'new-password/:userId/:code',
          element: (
            <GuestGuard>
              <NewPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'activate/:userId/:code',
          element: (
            <GuestGuard>
              <AccountActivation />
            </GuestGuard>
          ),
        },
      ],
    },

    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout layout="articles" />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        {
          path: 'articles',
          element: <MainPage />,
          children: [{ path: ':idTab', element: <MainPage /> }],
        },
      ],
    },

    {
      path: '/dashboard/saved-searches',
      element: (
        <AuthGuard>
          <DashboardLayout layout="default" />
        </AuthGuard>
      ),
      children: [{ path: '', element: <Favorite /> }],
    },
    {
      path: '/dashboard/emails',
      element: (
        <AuthGuard>
          <DashboardLayout layout="default" />
        </AuthGuard>
      ),
      children: [{ path: '', element: <Template /> }],
    },

    {
      path: '/shared',
      element: (
        <FirstEntryPageLayout>
          <FirstEntryPage />
        </FirstEntryPageLayout>
      ),
      children: [{ path: ':templateID/index/:index', element: <FirstEntryPage /> }],
    },
    {
      path: '/404',
      element: (
        <CompactLayout>
          <Page404 />
        </CompactLayout>
      ),
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
